import App from "./App.vue";
import { baseURL } from "./constants.ts";
import i18n from "./plugins/i18n.js";
import router from "./router";
import { useStore } from "./stores/authentication.js";
import axios from "axios";
import { createPinia } from "pinia";
import { createApp } from "vue";
import { FontAwesomeIcon, FontAwesomeLayers } from "./plugins/icons";
import * as Sentry from "@sentry/vue";
import "./assets/tailwind.css";
import { useLocalizationStore } from "./stores/localization";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

const parseJwt = (token: string) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );

    return JSON.parse(jsonPayload);
};

const DEBUG = process.env.NODE_ENV === "development";

const app = createApp(App);
const pinia = createPinia();

app.use(i18n);
app.use(router);
app.use(pinia);

app.component("FontAwesomeIcon", FontAwesomeIcon);
app.component("FontAwesomeLayers", FontAwesomeLayers);
app.component("VueDatePicker", VueDatePicker);

app.directive("uppercase", (element, binding) => {
    if (binding.value === undefined || binding.value === true) {
        element.value = element.value.toUpperCase();
        // this is required to force update the element
        // otherwise, the last character won't be uppercase
        // https://github.com/vuejs/vue/issues/2804
        element.dispatchEvent(new Event("input"));
    }
});

if (DEBUG) {
    app.config.performance = true;
}

const base = axios.create({
    baseURL: baseURL,
});

// since you can't use inject() outside a component.
// we can use this to inject the axios instance into
// the Pinia store.
const axiosPlugin = () => {
    return { $axios: base };
};

pinia.use(axiosPlugin);

const authStore = useStore();

if (authStore.loggedIn()) {
    base.defaults.headers.common["Authorization"] = `Bearer ${authStore.user.access_token}`;
}

base.interceptors.request.use(
    async function (request) {
        const allowedUrls = [
            "/tyrewatch/auth/token",
            "/tyrewatch/auth/refresh",
            "/tyrewatch/auth/otp",
            "/tyrewatch/auth/forgot",
            "/tyrewatch/auth/reset",
        ];

        if (request.url && allowedUrls.includes(request.url)) {
            return request;
        }

        // If the user is not logged in, redirect to the login page.
        if (!authStore.loggedIn()) {
            await router.push("/login");
            return Promise.reject();
        }

        // If the user's access token is expired, attempt to refresh.
        const tokenData = parseJwt(authStore.user.access_token);
        const datetime = Date.now() / 1000;
        if (tokenData.exp <= datetime) {
            // If the refresh token is also expired, redirect to the login page.
            const refreshTokenData = parseJwt(authStore.user.refresh_token);
            if (refreshTokenData.exp <= datetime) {
                await router.push("/login");
                return Promise.reject();
            }
            // If there is a valid refresh token, attempt to refresh.
            else {
                const newUser = await authStore.refresh();
                if (newUser === null) {
                    await router.push("/login");
                    return Promise.reject();
                }
                base.defaults.headers.common["Authorization"] = `Bearer ${newUser.access_token}`;
                request.headers["Authorization"] = `Bearer ${newUser.access_token}`;
            }
        }
        return request;
    },
    function (error) {
        Promise.reject(error);
    }
);

base.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (!error.response) {
            return Promise.reject(error);
        }
        // If we get a 401 response code, we know the user is not authenticated.
        // We can then redirect the user to the login page.
        if (error.response.status === 401) {
            authStore.logout();
            router.push("/login");
        }
        return Promise.reject(error);
    }
);

if (import.meta.env.VITE_SENTRY_DSN) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
        ],
        tracesSampleRate: 0.2,
        maxBreadcrumbs: 50,
        release: import.meta.env.VITE_AWS_COMMIT_ID,
    });
} else {
    console.debug("Sentry DSN not found, skipping initialization");
}

const store = useLocalizationStore();

store.$subscribe((mutation, state) => {
    i18n.global.locale.value = state.displayLanguage;
});

app.config.globalProperties.$axios = base;

app.provide("$axios", base);
app.mount("#app");
