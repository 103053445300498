import { useStore } from "./stores/authentication.js";
import { useUserStore } from "./stores/userStore.ts";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/login",
        name: "Login",
        component: () => import("./views/LoginPage.vue"),
        meta: {
            title: "Login - AutoTrust",
        },
    },
    {
        path: "/otp",
        name: "OneTimePassword",
        component: () => import("./views/OneTimePassword.vue"),
        meta: {
            title: "One Time Password Authentication - AutoTrust",
        },
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import("./views/ForgotPassword.vue"),
        meta: {
            title: "Forgot Password Authentication - AutoTrust",
        },
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("./views/ResetPassword.vue"),
        meta: {
            title: "Reset Password - AutoTrust",
        },
    },
    {
        path: "/sensors",
        name: "Sensors",
        component: () => import("./Sensors/views/ManageSensors.vue"),
        meta: {
            title: "Sensors",
            requiresAdmin: true,
        },
    },
    {
        path: "/vehicles",
        name: "Vehicles",
        component: () => import("./vehicles/views/ManageVehicles.vue"),
        meta: {
            title: "Vehicles",
        },
    },
    {
        path: "/",
        name: "Home",
        component: () => import("./vehicles/views/ManageVehicles.vue"),
        meta: {
            title: "Vehicles",
        },
    },
    {
        path: "/fleets",
        name: "ManageFleets",
        component: () => import("./fleets/views/ManageFleets.vue"),
        meta: {
            title: "Fleets",
        },
    },
    {
        path: "/fleets/:name",
        name: "ManageFleet",
        component: () => import("./fleets/views/FleetBase.vue"),
        meta: {
            title: "Fleet",
        },
        children: [
            {
                path: "vehicles",
                name: "FleetVehicles",
                component: () => import("./fleets/views/FleetVehicles.vue"),
            },
            {
                path: "depots",
                name: "FleetDepots",
                alias: "/fleets/:name",
                component: () => import("./fleets/views/FleetDepots.vue"),
            },
            {
                path: "map",
                name: "FleetMap",
                component: () => import("./fleets/views/FleetMap.vue"),
            },
            {
                path: "list",
                name: "FleetList",
                component: () => import("./fleets/views/FleetList.vue"),
            },
            {
                path: "pmiReport",
                name: "FleetPMI",
                component: () => import("./fleets/views/FleetPMI.vue"),
            },
            {
                path: "kilometerReport",
                name: "FleetKilometers",
                component: () => import("./fleets/views/FleetKilometers.vue"),
            },
            {
                path: "carbonReport",
                name: "FleetCarbonReport",
                component: () => import("./fleets/views/FleetCarbonReport.vue"),
            },
        ],
    },
    {
        path: "/telematics/:imei",
        name: "TelematicsUnit",
        component: () => import("./telematics-units/views/TelematicsUnitBase.vue"),
        children: [
            {
                path: "overview",
                name: "TelematicsUnitOverview",
                alias: "/telematics/:imei",
                component: () => import("./telematics-units/views/TelematicsUnitOverview.vue"),
            },
            {
                path: "logs",
                name: "TelematicsUnitConfigurator",
                alias: "/telematics/:imei",
                component: () => import("./telematics-units/views/TelematicsUnitConfigurator.vue"),
            },
        ],
    },
    {
        path: "/organization",
        name: "Organization",
        component: () => import("./organization/views/OrganizationBase.vue"),
        meta: {
            title: "Organization",
            requiresAdmin: true,
        },
        children: [
            {
                path: "settings",
                name: "OrganizationSettings",
                alias: "/organization",
                component: () => import("./organization/views/OrganizationSettings.vue"),
            },
            {
                path: "members",
                name: "OrganizationMembers",
                component: () => import("./organization/views/OrganizationMembers.vue"),
            },
            {
                path: "organizations",
                name: "OrganizationHierachy",
                component: () => import("./organization/views/OrganizationHierachy.vue"),
            },
            {
                path: "integrations",
                name: "OrganizationIntegrations",
                component: () => import("./organization/views/OrganizationIntegrations.vue"),
            },
        ],
    },
    {
        path: "/vehicles/new",
        name: "NewVehicles",
        component: () => import("./vehicles/views/NewVehicle.vue"),
        meta: {
            title: "Add Vehicle",
            requiresAdmin: true,
        },
    },
    {
        path: "/utilities/checksumCalculator",
        name: "ChecksumCalculator",
        component: () => import("./internal-utilities/views/ChecksumCalculator.vue"),
    },
    {
        path: "/vehicles/:id",
        name: "ManageVehicle",
        component: () => import("./vehicles/views/VehicleBase.vue"),
        children: [
            {
                path: "overview",
                name: "VehicleOverview",
                alias: "/vehicles/:id",
                component: () => import("./vehicles/views/VehicleOverview.vue"),
            },
            {
                path: "maintenance",
                name: "VehicleMaintenance",
                component: () => import("./vehicles/views/VehicleMaintenance.vue"),
            },
            {
                path: "environmental",
                name: "VehicleEnvironmental",
                component: () => import("./vehicles/views/VehicleEnvironmental.vue"),
            },
            {
                path: "checklist",
                name: "VehicleChecklist",
                component: () => import("./vehicles/views/VehicleChecklist.vue"),
            },
            {
                path: "sensors",
                name: "VehicleSensors",
                component: () => import("./vehicles/views/VehicleSensors.vue"),
            },
            {
                path: "config",
                name: "VehicleConfig",
                component: () => import("./vehicles/views/VehicleConfig.vue"),
            },
        ],
        meta: {
            title: "{} - Overview",
            paramTitle: true,
        },
    },
    {
        path: "/vehicles/:id/download",
        name: "VehicleDownload",
        component: () => import("./vehicles/views/VehicleDownload.vue"),
        meta: {
            title: "Vehicle Download",
        },
    },
    {
        path: "/vehicles/:id/dataExplorer",
        name: "VehicleDataExplorer",
        component: () => import("./vehicles/views/VehicleDataExplorer.vue"),
    },
    {
        path: "/kit/sim",
        name: "SimCards",
        component: () => import("./SIMCards/views/SimCardView.vue"),
        meta: {
            title: "SIM Cards",
            requiresAdmin: true,
        },
    },
    {
        path: "/kit/sim/:id",
        name: "EditSimCard",
        component: () => import("./SIMCards/views/EditSimCard.vue"),
        meta: {
            title: "{} - Edit SIM Card",
            paramTitle: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/kit/telematics/",
        name: "TelematicsUnits",
        component: () => import("./telematics-units/views/ManageTelematics.vue"),
        meta: {
            title: "Telematics Boxes",
            requiresAdmin: true,
        },
    },
    {
        path: "/reports",
        name: "Reports",
        component: () => import("./views/ManageReports.vue"),
        meta: {
            title: "Reports",
            requiresAdmin: true,
        },
    },
    {
        path: "/reports/new",
        name: "NewReport",
        component: () => import("./views/NewReport.vue"),
        meta: {
            title: "Add Report",
            requiresAdmin: true,
        },
    },
    {
        path: "/reports/edit/:id",
        name: "EditReport",
        component: () => import("./views/EditReport.vue"),
        meta: {
            title: "{} - Edit Report",
            paramTitle: true,
            requiresAdmin: true,
        },
    },
    {
        path: "/settings",
        name: "BaseSettings",
        component: () => import("./settings/views/SettingsBase.vue"),
        meta: {
            title: "Settings",
            requiresPermission: "user:settings",
        },
        children: [
            {
                path: "user",
                name: "UserSettings",
                alias: "/settings/",
                component: () => import("./settings/views/UserSettings.vue"),
            },
            {
                path: "alerts",
                name: "AlertSettings",
                component: () => import("./settings/views/AlertSettings.vue"),
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("./views/NotFound.vue"),
        meta: {
            title: "404 Not Found",
        },
    },
    {
        path: "/500",
        name: "InternalServerError",
        component: () => import("./views/InternalServerError.vue"),
        meta: {
            title: "500 Internal server error",
        },
    },
    {
        path: "/invalid-resource",
        name: "InvalidResource",
        component: () => import("./views/InvalidResource.vue"),
        meta: {
            title: "Cannot Access Resource",
        },
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach((to) => {
    const authStore = useStore();

    if (
        !authStore.loggedIn() &&
        !["Login", "OneTimePassword", "ForgotPassword", "ResetPassword"].includes(to.name)
    ) {
        return {
            name: "Login",
            query: { redirect: `${to.fullPath}` },
        };
    }

    if (authStore.loggedIn()) {
        const userStore = useUserStore();
        if (
            (to.meta.requiresAdmin && !authStore.isAdmin) ||
            (to.meta.requiresPermission && !userStore.hasPermission(to.meta.requiresPermission))
        ) {
            return { name: "NotFound" };
        }
    }

    document.title = handleTitle(to);
});

// Hard-reload the page when chunk load errors match the navigation error
// https://github.com/vitejs/vite/issues/11804#issuecomment-1760951463
router.onError((error, to) => {
    const errors = [
        "Failed to fetch dynamically imported module",
        "Unable to preload CSS",
        "Importing a module script failed",
    ];
    if (errors.some((e) => error.message.includes(e))) {
        window.location = to.fullPath;
    }
});

const handleTitle = (to) => {
    if (!to.meta.title) return "AutoTrust";

    if (to.meta.paramTitle) {
        const param = to.params.id;
        return to.meta.title.replace("{}", param);
    }

    return to.meta.title;
};

export default router;
